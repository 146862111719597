import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    CardContent,
    Grid
} from '@mui/material';
import { subscriberService } from '../../services/SubscribersService';
import { SelectListModel } from '../../models/ApiResponse';
import Controls from '../../utility/controls/Controls';
import { societiesService } from '../../services/SocietiesService';
import useForm from '../../utility/hooks/UseForm';
import { SocietiesModel } from '../../models/SocietiesModel';
import { useNavigate, useParams } from 'react-router-dom';
import { globalService } from '../../services/GlobalService';

const LinkMySociety = (...props: any) => {
    const navigate = useNavigate();
    const { societyId } = useParams();
    const [subscribers, setSubscribers] = useState<SelectListModel[]>([]);
    const [openDialog, setOpenDialog] = useState(true);

    useEffect(() => {
        if (subscribers.length === 0)
            getSubscriberName();
        getSocieties(societyId);
    }, []);

    const validate = (fieldValues: SocietiesModel = values) => {
        let temp: any = { ...errors };
        setErrors({
            ...temp,
        });
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(societiesService.initialFieldValues, validate, props.setCurrentId);


    const getSubscriberName = () => {
        subscriberService
            .getSelectList()
            .then((response: any) => {
                setSubscribers(response.data);
            });
    };

    const getSocieties = (societyId: any) => {
        var societyId = societyId.toString();
        societiesService.getById(societyId).then((response) => {
            if (response) {
                let result = response.data;
                values.societyname = result.data.Society
            }
        });
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate(-1);
    };

    const linkSociety = () => {
        values.SocietyId = societyId;
        
        societiesService.Link(values).then((response) => {
            if (response.data && response.data?.isSuccess) {
                globalService.success(response.data?.message);
                navigate(-1)
            }
            else {
                globalService.error("link society.");
            }
        });
    }

    return (
        <>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="link-my-society-dialog-title"
            >
                <DialogTitle id="link-my-society-dialog-title">
                    Link My Society ({values.societyname})
                </DialogTitle>
                <DialogContent>
                    <CardContent>
                        <React.Fragment>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <Controls.Select
                                        showEmptyItem={false}
                                        name="SubscriberId"
                                        label="Subscribers"
                                        required
                                        value={subscribers.length > 0 ? values.SubscriberId : ''}
                                        onChange={handleInputChange}
                                        options={subscribers}
                                        error={errors.SubscriberId}
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </CardContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} variant="outlined">
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            linkSociety()
                        }}
                    >
                        Link
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LinkMySociety;
