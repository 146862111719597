import { Guid } from "guid-typescript";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { TraceUserLogModel, UserLogModel } from "../models/TraceUserLogModel";

const initialFieldValues: TraceUserLogModel = {
  TraceUserLogId: Guid.EMPTY,
  UserId: "",
  UserName: "",
  UserIpaddress: "",
  ActivityType: "",
  ActivityOn: null,
};
const initialFieldValuesUser: UserLogModel = {
  UserId: "",
  FromDate: null,
  ToDate: null,
};

const url = "/api/TraceUserLogs";

function post(payload: TraceUserLogModel) {
  return customAxios.post(`${url}/Add`, payload, axiosRetryConfig);
}

function getUserLog(payload: UserLogModel) {
    return customAxios.post(`${url}/GetUserLog`, payload, axiosRetryConfig);
  }

export const TraceUserLogService = {
  initialFieldValues,
  initialFieldValuesUser,
  getUserLog,
  post,
};
