// import React from 'react'

// function MWhatsNewsForm() {
//   return (
//     <div>MWhatsNewsForm</div>
//   )
// }

// export default MWhatsNewsForm

import React, { useEffect } from "react";
import { Grid, TextField, CardActions, Card, CardContent, Button, Typography, Stack } from "@mui/material";
import { MWhatsNewsService } from "../../services/MWhatsNewsService";
import { useNavigate, useParams } from "react-router-dom";
import { globalService } from "../../services/GlobalService";
import useForm from "../../utility/hooks/UseForm";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MWhatsNewsModel } from "../../models/MWhatsNewsModel";
import Controls from "../../utility/controls/Controls";
import { ROLES } from "../../utility/Config";
import { AuthContext } from "../../utility/context/AuthContext";

const MWhatsNewsForm = (...props: any) => {
  const { auth } = React.useContext(AuthContext);
  globalService.pageTitle = "MWhatsNewss";
  const { whatsNewId } = useParams();
  let navigate = useNavigate();
  const mode = whatsNewId ? "Edit" : "Create";

  const validate = (fieldValues = values) => {
    let temp: any = { ...errors };
    if ("MWhatsNews" in fieldValues)
      temp.MWhatsNews = fieldValues.MWhatsNews ? "" : "MWhatsNews name is required.";

    // if ("Abrv" in fieldValues)
    // temp.Abrv = fieldValues.Abrv ? "" : "Abbreviation is required.";
    setErrors({
      ...temp,
    });



    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(MWhatsNewsService.initialFieldValues, validate, props.setCurrentId);

  const newUser = () => {
    setValues(MWhatsNewsService.initialFieldValues);
  };
  //This is used since in get the null property is not returned
  function setFormValue(model: MWhatsNewsModel) {
    let newModel = {
      WhatsNewId: model.WhatsNewId,
      WhatsNewTitle: model.WhatsNewTitle,
      Active: model.Active,
      WhatsNew: model.WhatsNew,
    }
    return newModel;
  }

  useEffect(() => {
    if (whatsNewId) {
      getMWhatsNews(whatsNewId);
      setErrors({});
    } else newUser();
  }, [whatsNewId]);

  
  const getMWhatsNews = (whatsNewId: any) => {
    
    MWhatsNewsService.getById(whatsNewId).then((response) => {
      if (response) {
        let result = response.data;
        
        setValues(setFormValue(result.data));
      }
    })
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {

      if (whatsNewId) {
        MWhatsNewsService.put(values).then((response: any) => {
          let result = response.data;
          if (response) {
            globalService.success(result.message);
            navigate("/whatsNews");
          } else {
            globalService.error(result.message);
          }
        });
      } else {
        MWhatsNewsService.post(values).then((response: any) => {
          if (response) {
            let result = response.data;
            if (result.isSuccess) {
              globalService.success(result.message);
              resetForm();
              navigate("/whatsNews");
            } else {
              globalService.error(result.message);
            }
          }
        });
      }
    }
  };

  return (
    <>
      <Typography variant="h5" align="center">
        What's New
      </Typography>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    label="What's New Title"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    name="WhatsNewTitle"
                    value={values.WhatsNewTitle}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label="What's New"
                    fullWidth
                    multiline
                    rows="3"
                    autoComplete="given-name"
                    variant="standard"
                    name="WhatsNew"
                    value={values.WhatsNew}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={4}  className={mode === "Create" ? "hidden" : ""}>
                  <Controls.Checkbox
                    name="Active"
                   
                    label="Active"
                    value={values.Active}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack spacing={2} direction="row">
              <Button type="submit" variant="contained">Submit</Button>
              <Button variant="outlined" startIcon={<ArrowBackIcon />} href="/whatsNews">Back </Button>
            </Stack>

          </CardActions>
        </Card>
      </form>
    </>
  );
};

export default MWhatsNewsForm;