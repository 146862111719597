import { Button, Card, CardActions, CardContent, Grid, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { globalService } from '../../services/GlobalService';
import { SoftwareFeaturesService } from '../../services/SoftwareFeaturesService';
import { SoftwareFeaturesModel } from '../../models/SoftwareFeaturesModel';
import useForm from '../../utility/hooks/UseForm';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Controls from '../../utility/controls/Controls';


const SoftwareFeaturesForm = (...props: any) => {
    const { softwareFeatureId } = useParams();
    let navigate = useNavigate();

    const validate = (fieldValues = values) => {
        let temp: any = { ...errors };
        if ("SoftwareFeature" in fieldValues)
            temp.SoftwareFeature = fieldValues.SoftwareFeature
              ? ""
              : "Software Feature  is required.";
        if ("RecSequence" in fieldValues) {
            temp.RecSequence = fieldValues.RecSequence || fieldValues.RecSequence === 0
                ? /^[0-9\b]+$/.test(fieldValues.RecSequence.toString())
                    ? ""
                    : "Sequence is not valid."
                : "Sequence is required";
        }
        setErrors({
            ...temp,
        });
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(SoftwareFeaturesService.initialFieldValues, validate, props.setCurrentId);

    const newUser = () => {
        setValues(SoftwareFeaturesService.initialFieldValues);
    };
    //This is used since in get the null property is not returned
    function setFormValue(model: SoftwareFeaturesModel) {
        let newModel = {
            SoftwareFeatureId: model.SoftwareFeatureId,
            SoftwareFeature: model.SoftwareFeature,
            RecSequence: model.RecSequence,        }
        return newModel;
    }

    useEffect(() => {
        if (softwareFeatureId) {
            getSoftwareFeature(softwareFeatureId);
            setErrors({});
        } else newUser();
    }, [softwareFeatureId]);

    const getSoftwareFeature = (softwareFeatureId: any) => {
        SoftwareFeaturesService.getById(softwareFeatureId).then((response) => {
            if (response) {
                let result = response.data;
                setValues(setFormValue(result.data));
            }
        })
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (validate()) {
            if (softwareFeatureId) {
                SoftwareFeaturesService.put(values).then((response: any) => {
                    let result = response.data;
                    if (response) {
                        globalService.success(result.message);
                        navigate("/softwareFeatures");
                    } else {
                        globalService.error(result.message);
                    }
                });
            } else {
                SoftwareFeaturesService.post(values).then((response: any) => {
                    if (response) {
                        let result = response.data;
                        if (result.isSuccess) {
                            globalService.success(result.message);
                            resetForm();
                            navigate("/softwareFeatures");
                        } else {
                            globalService.error(result.message);
                        }
                    }
                });
            }
        }
    };

    return (
        <>
            <Typography variant="h5" align="center">
                Software Features
            </Typography>
            <form
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit}>
                <Card>
                    <CardContent>
                        <React.Fragment>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        multiline
                                        label="Software Feature"
                                        fullWidth
                                        autoComplete="given-name"
                                        variant="standard"
                                        name="SoftwareFeature"
                                        value={values.SoftwareFeature}
                                        onChange={handleInputChange}
                                        error={errors.SoftwareFeature}
                                        helperText={errors.SoftwareFeature}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controls.Input
                                        required
                                        label="Sequence"
                                        fullWidth
                                        autoComplete="given-name"
                                        name="RecSequence"
                                        type="Number"
                                        inputProps={{ min: 0 }} 
                                        value={values.RecSequence}
                                        onChange={handleInputChange}
                                        error={errors.RecSequence}
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Stack spacing={2} direction="row">
                            <Button type="submit" variant="contained">Submit</Button>
                            <Button variant="outlined" startIcon={<ArrowBackIcon />} href="/softwareFeatures">Back </Button>
                        </Stack>
                    </CardActions>
                </Card>
            </form>
        </>
    )
}

export default SoftwareFeaturesForm