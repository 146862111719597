import React, { useEffect, useState } from 'react'
import { AuthContext } from '../../utility/context/AuthContext';
import { appInfoService } from '../../services/AppInfoService';
import { societiesService } from '../../services/SocietiesService';
import Carousel from 'nuka-carousel';
import { Box, Container, CssBaseline, Typography } from '@mui/material';

const About = () => {
    const [indexHtml, setIndexHtml] = useState('');
    // const { auth, setAuth } = React.useContext(AuthContext);
   
    function getAppInfoHtml() {
      appInfoService.getAllHtml().then((response) => {
        if (response) {
          let result = response.data;
          if (result.isSuccess) {
            setIndexHtml(result.row?.HomePageHtml);
            // localStorage.setItem('HomePageEmail', result.row.Email ?? '');
            // localStorage.setItem('HomePageMobile', result.row.Mobile ?? '');
          }
        }
      });
    };
  
    useEffect(() => {
      console.log('Index Page called 1');
      societiesService.clearStorageForIndex();
  
      if (!indexHtml)
        getAppInfoHtml();
    },[]);
  
    return (
      
      <>
        <Typography variant="h5" align="center">
            About Us
        </Typography>
       
        <React.Fragment>
          <CssBaseline />
          {/* <img src="banner.png" alt=""  width="100%"/> */}
          <Container>
            <Box>
              <div dangerouslySetInnerHTML={{ __html: indexHtml }} />
            </Box>
          </Container>
          <br />
          <br />
        </React.Fragment>
        
      </>
    );
}

export default About;