import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";

const url = '/api/Dashboard';

function getForDashboardPartA(SocietyId: string) {
    return customAxios.get(`${url}/GetForDashboardPartA/${SocietyId}`, axiosRetryConfig);
}

function getForDashboardPartB(SocietyId: string) {
    return customAxios.get(`${url}/GetForDashboardPartB/${SocietyId}`, axiosRetryConfig);
}

function getAcBalancesForSociety(SocietyId: string) {
    return customAxios.get(`${url}/GetAcBalancesForSociety/${SocietyId}`, axiosRetryConfig);
}
function getDashboardMissingFeaturesList(SocietyId: string) {
    return customAxios.get(`${url}/GetDashboardMissingFeaturesList/${SocietyId}`, axiosRetryConfig);
}
function getDashboardPGSummary(SocietyId: string) {
    return customAxios.get(`${url}/GetDashboardPGSummary/${SocietyId}`, axiosRetryConfig);
}
function getDashboardReceiptSummary(societyId: string) {
    return customAxios.get(`${url}/GetDashboardReceiptSummary/${societyId}`, axiosRetryConfig);
}

export const dashboardService = {
    getForDashboardPartA,
    getForDashboardPartB,
    getAcBalancesForSociety,
    getDashboardMissingFeaturesList,
    getDashboardPGSummary,
    getDashboardReceiptSummary
};