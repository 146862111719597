import { Box, Container, CssBaseline, Toolbar, Typography } from "@mui/material";
import Carousel from "nuka-carousel";
import React, { useEffect, useState } from "react";
import ContactForm from "./ContactForm";
import { config } from "../../utility/Config";
import { appInfoService } from "../../services/AppInfoService";
import { AuthContext } from "../../utility/context/AuthContext";
import { societiesService } from "../../services/SocietiesService";
import { MWhatsNewsService } from "../../services/MWhatsNewsService";

const Index = () => {
  const [indexHtml, setIndexHtml] = useState('');
  const { auth, setAuth } = React.useContext(AuthContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const[features,setfeaturse] = useState([]);


  useEffect(() => {
    if (features.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
      }, 11000); // Change the record every 2 seconds

      return () => clearInterval(interval); // Clean up the interval on component unmount
    }
  }, [features]);

  const getSoftwareFeatures = () => {
    MWhatsNewsService.getSoftwareFeatures()
      .then((Response) => {
        let result = Response.data;
        if (result && result.list) {
          setfeaturse(result.list);
        }
      })
     
  };

  function getAppInfoHtml() {
    appInfoService.getAllHtml().then((response) => {
      if (response) {
        let result = response.data;
        if (result.isSuccess) {
          setIndexHtml(result.row?.HomePageHtml);
          localStorage.setItem('HomePageEmail', result.row.Email ?? '');
          localStorage.setItem('HomePageMobile', result.row.Mobile ?? '');
        }
      }
    });
  };

  useEffect(() => {
    console.log('Index Page called 1');
    societiesService.clearStorageForIndex();
    setAuth(null);
    getSoftwareFeatures();
    if (!indexHtml)
      getAppInfoHtml();
  }, []);

  return (

    <>
  
      <CssBaseline />
          <div style={{ position: 'relative', textAlign: 'center', color: 'black' }}>
            <img src="images/banner.png"  className= "banner-image" alt="" width="100%" height="500px" />
            {features.length > 0 && (
               <div
               key={currentIndex}
               style={{
                 position: 'absolute',
                 top: '80%',
                 left: '50%',
                 transform: 'translate(-50%, -50%)',
                // backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: To make text more readable
                 padding: '10px',
                 textAlign: 'center',
               }}
               className="slide-text-container"
             >
                <h1 className="slide-text">{features[currentIndex].SoftwareFeature}</h1>
               
              </div>
            )}
          </div>
          <Container>
          <Box>
            <div dangerouslySetInnerHTML={{ __html: indexHtml }} />
          </Box>
        </Container>
    

      <ContactForm />
 
     {/*<Carousel>
        <img src="images/banner.png" width="100%" />
      </Carousel>
      <React.Fragment>
        <CssBaseline />
        <img src="banner.png" alt="" width="100%" />
        
      </React.Fragment>

      <ContactForm /> */}
    </>
  );
}

export default Index;