import React, { FC, ReactElement, useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { AuthContext } from "../../utility/context/AuthContext";

export const Footer: FC = (): ReactElement => {
  const { auth, setAuth } = React.useContext(AuthContext);
  let isLoggedIn = auth?.Token ? true : false;
  const [visibleLinks, setVisibleLinks] = useState([]);
  let isSSSociety = process.env.REACT_APP_BASE_URL.includes("sssociety");
  //isSSSociety = true;

  useEffect(() => {
    isLoggedIn = auth?.Token ? true : false;
    const links = [
      {
        href: "/privacyPolicy",
        text: "Privacy Policy",
        isVisible: isSSSociety
      },
      {
        href: "/refundPolicy",
        text: "Refund / Cancellation Policy",
        isVisible: isSSSociety,
      },
      {
        href: "/termsandConditions",
        text: "Terms and Conditions",
        isVisible: isSSSociety,
      },
      {
        href: "/aboutus",
        text: "About Us",
        isVisible: isSSSociety
      },
      {
        href: "/contactus",
        text: "Contact Us",
        isVisible: isSSSociety
      },
      {
        href: "/mWhatsNews",
        text: "What's New",
        isVisible: isLoggedIn && isSSSociety,
      },
    ];
    setVisibleLinks(links.filter((x) => x.isVisible === true));
  }, [isLoggedIn]);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "var(--primary-color)",
        paddingTop: "0.5rem",
        paddingBottom: "0.6rem",
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="footer"
        >
          <Grid item xs={12}>
            <Typography color="#e6e6fa" variant="subtitle1" align="center">
              2023 © All Rights Reserved | Designed and Developed by
              <Link
                color="#00ff00"
                variant="subtitle1"
                underline="none"
                href="http://www.sentientsystems.net/"
                target="_blank"
                sx={{ marginLeft: "8px" }}
              >
                Sentient Systems Pvt Ltd.
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              alignItems="center"
              justifyContent="center"
              sx={{ textAlign: { xs: "center", sm: "inherit" } }}
            >

              {visibleLinks.map((link, index) => (
                <React.Fragment key={link.href}>
                  <Link
                    color="aqua"
                    variant="subtitle1"
                    underline="none"
                    href={link.href}
                    target="_blank"
                  >
                    {link.text}
                  </Link>
                  {index < visibleLinks.length - 1 && (
                    <Divider
                      color="aqua"
                      orientation="vertical"
                      flexItem
                      sx={{
                        display: { xs: "none", sm: "block" },
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;

////////////////////Below From Template///////////////////////
// import * as React from "react";
// import { Link, Box, Container, Typography } from "@mui/material";

// function Copyright() {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" >
//       {'Copyright © '}
//       <Link color="inherit" href="https://google.com/">
//         Perfect Society
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//       <br />
//       Created By <Link color="inherit" href="http://www.sentientsystems.net/">Sentient Systems</Link>
//     </Typography>
//   );
// }

// interface FooterProps {
//   description: string;
//   title: string;
// }

// export default function Footer(props: FooterProps) {
//   const { description, title } = props;

//   return (
//     <Box component="footer" sx={{ bgcolor: "background.paper", py: 6 }}>
//       <Container maxWidth="lg">
//         <Typography variant="h6" align="center" gutterBottom>
//           {/* {props.title} */}
//           {title}
//         </Typography>
//         <Typography
//           variant="subtitle1"
//           align="center"
//           color="text.secondary"
//           component="p"
//         >
//           {/* {props.description} */}
//           {description}
//         </Typography>
//         <Copyright />
//       </Container>
//     </Box>
//   );
// }
