import React, { useEffect, useState } from 'react'
import { SoftwareFeaturesService } from '../../services/SoftwareFeaturesService'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { Button, Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from 'react-router-dom';
import { globalService } from '../../services/GlobalService';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ConfirmDialog from '../helper/ConfirmDialog';

const SoftwareFeaturesList = () => {
    const [softwareFeatures, setSoftwareFeatures] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '', onConfirm: () => { } })
    const navigate = useNavigate();


    useEffect(() => {
        getSoftwareFeatures();
    }, []);



     
    const getSoftwareFeatures = () => {
        SoftwareFeaturesService.getAll().then((response) => {
            let result = response.data;
            setSoftwareFeatures(result.list);
        })
    }

    const columns: GridColDef[] = [
        { field: "SoftwareFeature", headerName: " Software Feature", flex: 1 },
        { field: "RecSequence", headerName: "Sequence", flex: 1 },
        {
            field: "Actions",
            headerName: "Actions",
            type: "number",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={0}>
                        <IconButton size="small"
                            color="primary"
                            aria-label="add an alarm"
                            onClick={() => navigate("/editsoftwareFeatures/" + params.row.SoftwareFeatureId)}
                        >
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton size="small" aria-label="delete" color="error"
                            onClick={() => {

                                setConfirmDialog({
                                    isOpen: true,
                                    title: 'Are you sure to delete this Software Feature ?',
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => { removeSoftwareFeatures(params.row.SoftwareFeatureId) }
                                })
                            }}>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>

                    </Stack>
                );
            },
        },
    ]

    const removeSoftwareFeatures = (SoftwareFeatureId: any) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        SoftwareFeaturesService.remove(SoftwareFeatureId).then((response) => {
            let result = response.data;
            if (response) {
                globalService.success(result.message);
                getSoftwareFeatures();
            }
        });
    }
    return (
        <>
            <Typography variant="h5" align="center">
                Software Features
            </Typography>
            <Card>
                <CardContent>
                    <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => navigate("/addsoftwareFeatures")}
                    >
                        Add Record
                    </Button>
                    <div style={{ width: "100%" }}>
                        <DataGrid
                            getRowId={(row) => row.SoftwareFeatureId}
                            rows={softwareFeatures}
                            columns={columns}
                            columnHeaderHeight={30}
                            //rowHeight={30}
                            autoHeight={true}
                            getRowHeight={() => "auto"}
                            getEstimatedRowHeight={() => 200}
                            //loading={loading}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        // Hide columns Id, the other columns will remain visible
                                        SoftwareFeatureId: false,
                                    },
                                },
                                pagination: { paginationModel: { pageSize: 10 } },
                            }}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50, 100]}
                        />
                    </div>
                </CardContent>
            </Card>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}

export default SoftwareFeaturesList