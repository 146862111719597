import { MWhatsNewsModel } from "../models/MWhatsNewsModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { Guid } from 'guid-typescript';

const initialFieldValues: MWhatsNewsModel = {
    WhatsNewId: Guid.EMPTY,
    WhatsNewTitle: "",
    Active: true,
    WhatsNew: ""
};
const url = 'api/MWhatsNews';

function getAll() {
  return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}
function post(payload: MWhatsNewsModel) {
  return customAxios.post(`${url}/Add`, payload, axiosRetryConfig);
}

function put(payload: MWhatsNewsModel) {
  return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig);
}

function remove(id: number) {
  return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(id: number) {
  return customAxios.get(`${url}/GetById/${id}`, axiosRetryConfig);
}

function getAllByActive() {
    return customAxios.get(`${url}/GetAllByActive`, axiosRetryConfig);
  }

  function getSoftwareFeatures(){
    return customAxios.get(`${url}/GetSoftwareFeatures`, axiosRetryConfig);
  }
export const MWhatsNewsService = {
  initialFieldValues,
  getAll,
  post,
  put,
  remove,
  getById,
  getSoftwareFeatures,
  getAllByActive
};