import { SoftwareFeaturesModel } from "../models/SoftwareFeaturesModel";
import { axiosRetryConfig, customAxios } from "./AxiosHttpCommon";
import { Guid } from 'guid-typescript';

const initialFieldValues: SoftwareFeaturesModel = {
    SoftwareFeatureId: Guid.EMPTY,
    SoftwareFeature: "",
    RecSequence: 0,
};
const url = 'api/SoftwareFeatures';

function post(payload: SoftwareFeaturesModel) {
    return customAxios.post(`${url}/Add`, payload, axiosRetryConfig);
}

function put(payload: SoftwareFeaturesModel) {
    return customAxios.put(`${url}/Edit`, payload, axiosRetryConfig);
}

function remove(id: number) {
    return customAxios.delete(`${url}/${id}`, axiosRetryConfig);
}

function getById(id: number) {
    return customAxios.get(`${url}/GetById/${id}`, axiosRetryConfig);
}

function getAll() {
  return customAxios.get(`${url}/GetAll`, axiosRetryConfig);
}

export const SoftwareFeaturesService = {
  initialFieldValues,
  getAll,
  post,
  put,
  remove,
  getById,
};