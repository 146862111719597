import React, { useEffect, useState } from 'react'
import ContactForm from './ContactForm'
import { Box, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material'
import { MWhatsNewsService } from '../../services/MWhatsNewsService';

const WhatsNew = (props: any) => {
  const [mWhatNews, setMWhatsNews] = useState([]);

  useEffect(() => {
    getMWhatsNews();
  }, []);

  const getMWhatsNews = () => {
    MWhatsNewsService.getAllByActive().then((response) => {
      let result = response.data;
      setMWhatsNews(result.list);
    });
  };

  return (
    <>
      <Typography variant="h5" align="center">
        What's New
      </Typography>

      {/* <Divider sx={{ my: 2 }} /> */}
      {mWhatNews.map((news) => (
        <>
          <Card>
            <CardHeader title={news.WhatsNewTitle} />
            <CardContent>
              <div dangerouslySetInnerHTML={{ __html: news.WhatsNew }} />
            </CardContent>
          </Card>
        </>
      ))}
    </>
  )
}

export default WhatsNew