import React, { useContext, useEffect, useState } from "react";
import Controls from "../../utility/controls/Controls";
import { globalService } from "../../services/GlobalService";
import useForm from "../../utility/hooks/UseForm";
import { TraceUserLogService } from "../../services/TraceUserLogService";
import { TraceUserLogModel } from "../../models/TraceUserLogModel";
import { AuthContext } from "../../utility/context/AuthContext";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { userService } from "../../services/UserService";
import { SelectListModel } from "../../models/ApiResponse";
import dayjs from "dayjs";

const TraceUserLog = () => {
  const auth = useContext(AuthContext);
  const userId = auth.auth.UserId;
  const [userLog, setUserLog] = useState([]);
  const [userData, setUserData] = useState<SelectListModel[]>([]);
  const validate = (fieldValues: TraceUserLogModel = values) => {
    let temp: any = { ...errors };

    if ("FromDate" in fieldValues)
      temp.FromDate = fieldValues.FromDate ? "" : "From Date is required.";
    if ("ToDate" in fieldValues)
      temp.ToDate = fieldValues.ToDate ? "" : "To Date is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(TraceUserLogService.initialFieldValuesUser, validate, userId);

  useEffect(() => {
    // if(userLog.length === 0) getUserLog();
    if (userData.length === 0) getUserData();
  }, []);

  const getUserData = () => {
    userService.getUserSelectList().then((response) => {
      let result = response.data;
      setUserData(result);
    });
  };

  // const getUserLog = () => {
  //   TraceUserLogService.getUserLog(values).then((response) => {
  //     let result = response.data;
  //     setUserLog(result.list);
  //   });
  // };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validate) {
        TraceUserLogService.getUserLog(values).then((response) => {
          console.log(response.data)
          let result = response.data;
          setUserLog(result.list);
        });
    }
  };

  const columns: GridColDef[] = [
    { field: "UserName", headerName: "User Name", flex: 1 },
    { field: "UserIpaddress", headerName: "User IP-address", flex: 1 },
    { field: "ActivityType", headerName: "Activity Type", flex: 1 },
    { field: "ActivityOn", headerName: "Activity On", flex: 1,
      valueFormatter: (params) => params.value ? dayjs(params.value).format('DD-MMM-YYYY hh:mm A') : ""
     },
  ];

  function getDefaultDate() {
    const currentDate = new Date();
    const oneMonthsAgo = new Date(currentDate);
    oneMonthsAgo.setMonth(currentDate.getMonth() - 1);
    return oneMonthsAgo;
  }

  return (
    <>
      <Typography variant="h5" align="center">
        User Log
      </Typography>
      <form action="" autoComplete="off" noValidate>
        <Card>
          <CardContent>
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Controls.Select
                    name="UserId"
                    label="User Name"
                    value={userData.length > 0 ? values.UserId : ""}
                    onChange={handleInputChange}
                    options={userData}
                    error={errors.UserId}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.ReactDatePicker
                    label="From Date"
                    value={(values.FromDate = values.FromDate || getDefaultDate())}
                    max={new Date()}
                    onChange={(date: Date) =>
                      handleInputChange({
                        target: {
                          value: globalService.convertLocalToUTCDate(date),
                          name: "FromDate",
                        },
                      })
                    }
                    error={errors.FromDate}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Controls.ReactDatePicker
                    label="To Date"
                    value={(values.ToDate = values.ToDate || new Date())}
                    max={new Date()}
                    onChange={(date: Date) =>
                      handleInputChange({
                        target: {
                          value: globalService.convertLocalToUTCDate(date),
                          name: "ToDate",
                        },
                      })
                    }
                    min={values.FromDate}
                    error={errors.ToDate}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Go
                  </Button>
                </Grid>
              </Grid>
              <div>
                <DataGrid
                  getRowId={(row) => row.TraceUserLogId}
                  rows={userLog}
                  columns={columns}
                  columnHeaderHeight={30}
                  //rowHeight={30}
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                  getEstimatedRowHeight={() => 200}
                  //loading={loading}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        // Hide columns Id, the other columns will remain visible
                        AcHeadId: false,
                      },
                    },
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  pageSizeOptions={[10, 25, 50, 100]}
                />
              </div>
            </React.Fragment>
          </CardContent>
        </Card>
      </form>
    </>
  );
};

export default TraceUserLog;
