import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  Button,
  Grid,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { Textarea } from "@mui/joy";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import IconButton from "@mui/material/IconButton";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";
import { config } from "../../utility/Config";
import { useEffect, useState } from "react";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";

export default function ContactForm() {
  let isSSSociety = process.env.REACT_APP_BASE_URL.includes("sssociety");
  const [currentLocation, setCurrentLocation] = useState({
    lat: null,
    lng: null,
  });
  const [mapUrl, setMapUrl] = useState("");
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const recipientEmail = localStorage.getItem("HomePageEmail");
    const mailtoLink = `mailto:${recipientEmail}?subject=Contact Form Submission&body=Name: ${encodeURIComponent(formData.name)}%0D%0AEmail: ${encodeURIComponent(formData.email)}%0D%0APhone: ${encodeURIComponent(formData.phone)}%0D%0AMessage: ${encodeURIComponent(formData.message)}`;
    window.location.href = mailtoLink;
  };
  useEffect(() => {
    setMapUrl(
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.47928781135!2d72.83659407970883!3d19.17425743576943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7bdbee66789%3A0xeff3eb8b518dfcff!2sEase%20Zone%20Mall!5e0!3m2!1sen!2sus!4v1723008257149!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
    );
  }, []);
  // const mapeazezone = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.47928781135!2d72.83659407970883!3d19.17425743576943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7bdbee66789%3A0xeff3eb8b518dfcff!2sEase%20Zone%20Mall!5e0!3m2!1sen!2sus!4v1723008257149!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade`

  return (
    <React.Fragment>
      <div style={{ paddingTop: 20 }}></div>
      <>
        <Box sx={{ bgcolor: "#e8f0f5", p: 5 }}>
          <Container>
            {/* <Box sx={{ bgcolor: '#cfe8fc', height: '40vh' }} /> */}
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  Contact Details
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <IconButton
                    aria-label=" AddLocationAltOutlinedIcon"
                    sx={{ color: "#002db3" }}
                  >
                    <AddLocationAltOutlinedIcon />
                  </IconButton>{" "}
                  Mumbai
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <IconButton
                    aria-label=" PhoneOutlined"
                    sx={{ color: "#002db3" }}
                  >
                    <PhoneOutlinedIcon />
                  </IconButton>{" "}
                  {localStorage.getItem("HomePageMobile")}
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <IconButton
                    aria-label=" EmailOutlined"
                    sx={{ color: "#002db3" }}
                  >
                    <EmailOutlinedIcon />
                  </IconButton>{" "}
                  {localStorage.getItem("HomePageEmail")}
                </Typography>
                {isSSSociety && mapUrl &&
                  <Box sx={{ mt: 3 }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "300px",
                        border: "2px solid #002db3",
                      }}
                    >
                      <iframe
                        title="Current Location"
                        src={mapUrl}
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        // allowFullScreen=""
                        loading="lazy"
                      ></iframe>
                    </Box>
                  </Box>
                }
              </Grid>
              <Grid item xs={6}>
                <form onSubmit={handleSubmit}>

                  <Box sx={{ maxWidth: '100%', '& > :not(style)': { m: 1 } }}>
                    <TextField
                      label="Name"
                      color="secondary"
                      variant="outlined"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton aria-label="Person2RoundedIcon" sx={{ color: '#002db3' }}>
                              <Person2RoundedIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      label="Email"
                      color="secondary"
                      variant="outlined"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton aria-label="EmailRoundedIcon" sx={{ color: '#002db3' }}>
                              <EmailRoundedIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      label="Phone"
                      color="secondary"
                      variant="outlined"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton aria-label="PhoneOutlined" sx={{ color: '#002db3' }}>
                              <AddIcCallIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      color="secondary"
                      fullWidth
                      multiline
                      rows="5"
                      label="Message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />

                    <Button variant="contained" size="small" type="submit">
                      Send Message
                    </Button>
                  </Box>

                </form></Grid>
            </Grid>
          </Container>
        </Box>
      </>
    </React.Fragment>
  );
}
