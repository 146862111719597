import React from 'react'
import ContactForm from './ContactForm'
import { Typography } from '@mui/material'

const Contact = () => {
  return (
    <>
      <Typography variant="h5" align="center">
        Contact Us
      </Typography>
     
      <ContactForm />
    </>
  )
}

export default Contact